
@import url('https://fonts.googleapis.com/css2?family=PT+Serif&display=swap');

html,
body {
  font-family: 'PT Serif', serif;
  background-color: rgb(245, 245, 245);
}

.container {
  margin: 80px auto 0 auto;
  max-width: 1200px;
}

.nav-container {
  margin: auto;
}

.nav-container svg {
  color: #fff;
}

a {
  text-decoration: none;
}
