.wrapper {
	display: flex;
	justify-content: flex-start;
	margin-left: 5%;
}
h1,
h2,
p {
	font-family: PT Serif;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.3;
	letter-spacing: normal;
}

h1.AboutHead {
	font-family: PT Serif;
}

h1.ProfileHead {
	width: 526px;
	font-family: PT Serif;
	font-size: 50px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.3;
	letter-spacing: normal;
	text-align: left;
	margin-block-start: 0;
	margin-block-end: 0;
}

h2.ProfileSec {
	height: 58px;
	font-family: PT Serif;
	font-size: 30px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.3;
	letter-spacing: normal;
	text-align: left;
	margin-block-start: 0;
	margin-block-end: 0;
}

.bio {
	font-size: 25px;
}

.likes {
	font-size: 20px;
}

h1.InterestHead {
	font-family: PT Serif;
}

p.info {
	font-size: 20px;
}
.leftside,
.rightside {
	padding: 10px;
	background-color: #fff;
}

.leftside {
	width: 30%;
}

.rightside {
	width: 60%;
	height: 100vh;
	top: 0;
	background-color: #fff;
}

.info {
	margin: 0 0 0;
	margin-block-start: 0;
	margin-block-end: 0;
}

@media (max-width: 480px) {
	h1.ProfileHead {
		width: 100%;
		font-family: PT Serif;
		font-size: 30px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: normal;
		text-align: left;
		margin-block-start: 0;
		margin-block-end: 0;
		margin-left: 20px;
	}

	h2.ProfileSec {
		height: 58px;
		font-family: PT Serif;
		font-size: 20px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: normal;
		text-align: left;
		margin-block-start: 0;
		margin-block-end: 0;
		margin-left: 20px;
	}
}
