:root {
	--height-lg-screens: 80px;
}

.Navbar {
	display: flex;
	justify-content: space-between;
	/* by default: align-items: strech -> child elements' heights = the container's height */

	height: var(--height-lg-screens); /* set a fixed height */
	/* background-color: var(--bg-color-lg-screens); */
	background-image: url(images/road.png);
	background: url(images/road.png);
	height: 100px;
}

.navbar-links {
	display: flex;
	/* align-items: stretch; by default */
}

.navbar-home,
.navbar-link {
	display: flex;
	align-items: center; /* we want children to be centered vertically*/
	color: white;
	padding: 16px; /* with paddings on the sides */
}

.navbar-home {
	height: var(--height-lg-screens); /* it needs to stay inside the nav bar */
	box-sizing: border-box;
}

.navbar-link {
	border-bottom: 2px solid var(--bg-color-lg-screens);
}

.navbar-link:hover {
	border-bottom-color: white;
}

.toggle {
	display: none;
	padding: 8px;
	font-size: 16px;
}

.logo {
	position: relative;
	top: 20%;
}

.tab {
	color: white;
}
a.nav-links {
	color: white;
	font-size: 16px;
}

/**
  *
  * Smaller screen + Mobile
  *
  */
@media screen and (max-width: 480px) {
	.logo {
		top: -1%;
		left: 1%;
		position: absolute;
		padding: 10px;
	}

	.Navbar {
		/*flex-direction: column;*/ /* change the direction of the container */
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: auto; /* overrides the height of 80px set for large screens */
		background-color: gray;
	}

	.navbar-home {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: auto; /* overrides the height of 80px set for large screens */
	}

	.navbar-links {
		flex-direction: column;
		background-color: black;
	}

	.navbar-links.closed {
		/* when toggled, hide the main nav*/
	}

	.toggle {
		display: block;
	}

	.navbar-link {
		border-bottom: none;
	}
}

/* reset style */
body,
ul {
	margin: 0;
	padding: 0;
	font-size: 14px;
}

li {
	list-style-type: none;
}

/* a {
	text-decoration: none;
	color: white;
} */

.navbar-link {
	color: white;
}
button {
	background-color: transparent;
	border: none;
}

button:focus {
	outline: 0;
}

.App {
	font-family: PT Serif;
	text-align: center;
}
