eventAddr.Icon {
  width: 44px;
  height: 44px;
  object-fit: contain;
}

.eventAddr {
  font-family: PT Serif;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
