.wrapper {
	display: flex;
	justify-content: flex-start;
}

.main,
.sidebar {
	padding: 10px;
	background-color: #fff;
}

.main {
	width: 70%;
}

.sidebar {
	width: 25%;
	height: auto;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	background-color: #fff;
}

/* @media (max-Width: 460px) {
	.wrapper {
		display: normal;
		justify-content: flex-start;
	}
	.main {
		position: relative;
		top: 270px;
		left: -90px;
	}
	.sidebar {
		width: 100%;
		left: 120px;
	}
} */
