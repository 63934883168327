.Filter-Bar-Style {
  position: relative;
  top: -10px;
  width: 300px;
  height: 300px;
  border-radius: 13px;
  background-color: #ffffff;
  padding: 10px;
}
.search-results-button {
  position: relative;
  float: right;
  top: -34px;
  right: 20px;
}

.search-results-button:hover {
  opacity: 0.9;
  cursor: pointer;
}


