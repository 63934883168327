@import url(https://fonts.googleapis.com/css2?family=PT+Serif&display=swap);

html,
body {
  font-family: 'PT Serif', serif;
  background-color: rgb(245, 245, 245);
}

.container {
  margin: 80px auto 0 auto;
  max-width: 1200px;
}

.nav-container {
  margin: auto;
}

.nav-container svg {
  color: #fff;
}

a {
  text-decoration: none;
}

:root {
	--height-lg-screens: 80px;
}

.Navbar {
	display: flex;
	justify-content: space-between;
	/* by default: align-items: strech -> child elements' heights = the container's height */

	height: 80px;

	height: var(--height-lg-screens); /* set a fixed height */
	/* background-color: var(--bg-color-lg-screens); */
	background-image: url(/static/media/road.b26cc45b.png);
	background: url(/static/media/road.b26cc45b.png);
	height: 100px;
}

.navbar-links {
	display: flex;
	/* align-items: stretch; by default */
}

.navbar-home,
.navbar-link {
	display: flex;
	align-items: center; /* we want children to be centered vertically*/
	color: white;
	padding: 16px; /* with paddings on the sides */
}

.navbar-home {
	height: 80px;
	height: var(--height-lg-screens); /* it needs to stay inside the nav bar */
	box-sizing: border-box;
}

.navbar-link {
	border-bottom: 2px solid var(--bg-color-lg-screens);
}

.navbar-link:hover {
	border-bottom-color: white;
}

.toggle {
	display: none;
	padding: 8px;
	font-size: 16px;
}

.logo {
	position: relative;
	top: 20%;
}

.tab {
	color: white;
}
a.nav-links {
	color: white;
	font-size: 16px;
}

/**
  *
  * Smaller screen + Mobile
  *
  */
@media screen and (max-width: 480px) {
	.logo {
		top: -1%;
		left: 1%;
		position: absolute;
		padding: 10px;
	}

	.Navbar {
		/*flex-direction: column;*/ /* change the direction of the container */
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: auto; /* overrides the height of 80px set for large screens */
		background-color: gray;
	}

	.navbar-home {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: auto; /* overrides the height of 80px set for large screens */
	}

	.navbar-links {
		flex-direction: column;
		background-color: black;
	}

	.navbar-links.closed {
		/* when toggled, hide the main nav*/
	}

	.toggle {
		display: block;
	}

	.navbar-link {
		border-bottom: none;
	}
}

/* reset style */
body,
ul {
	margin: 0;
	padding: 0;
	font-size: 14px;
}

li {
	list-style-type: none;
}

/* a {
	text-decoration: none;
	color: white;
} */

.navbar-link {
	color: white;
}
button {
	background-color: transparent;
	border: none;
}

button:focus {
	outline: 0;
}

.App {
	font-family: PT Serif;
	text-align: center;
}

.footer{
    font-family: PT Serif;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    background-color:#707070;
    margin-top: 5px;

    
}

.footerText{
    margin-right: 7%;
    margin-left: 7%;
}

.email{
    font-size: 25px;
}
.loc{
    font-size: 20px;
}

h1.aboutheader{
    font-family: PT Serif;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    font-size: 20px
}

p.about{
    font-size: 15px
}

p.copyright{
    font-family: PT Serif;
    font-size: 23px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.snsbutton{
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.tandc{
    color: white
}
.Filter-Bar-Style {
  position: relative;
  top: -10px;
  width: 300px;
  height: 300px;
  border-radius: 13px;
  background-color: #ffffff;
  padding: 10px;
}
.search-results-button {
  position: relative;
  float: right;
  top: -34px;
  right: 20px;
}

.search-results-button:hover {
  opacity: 0.9;
  cursor: pointer;
}



title {
  word-wrap: break-word; /* All browsers since IE 5.5+ */
  overflow-wrap: break-word; /* Renamed property in CSS3 draft spec */
  width: 100%;
}

.topHeading {
  vertical-align: baseline;
  font-weight: 400;
  font-family: PT Serif;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}
div.Card:hover {
  /* opacity: 99%; */
  cursor: pointer;
}

.h1 {
  font-family: PT Serif;
  font-size: 25px;
  font-weight: 400;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.bottomPortion{
    position: relative;
    top: 550px;
}

.priceDescription{
    position: absolute;
    top: 520px;
}


.Banner {
  height: 800px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}


.Banner-quotes {
position: relative;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 40px;
    top: -10px;
}

  p.Banner-q {
    font-family: PT Serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    margin-bottom: 0;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }



  h1.Banner-q {
    font-family: PT Serif;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
  }

.FilterBar {
  width: 527px;
  height: 448px;
  border-radius: 13px;
  background-color: #ffffff;
  align-items: center;
  
}
div.FilterBar-syle-div {
  align-items: center;
  padding: 40px;
  right: 10%;
}



h1.container {
  padding-left: 20px;
}
p.container {
  padding-left: 20px;
}

.EventCardText {
    font-family: PT Serif;
}


@media (max-width: 970px) {
  p.Banner-q {
    font-family: PT Serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    margin-bottom: 0;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  h1.Banner-q {
    font-family: PT Serif;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
  }

 div.FilterBar-syle-div {
  position: relative;
  align-items: center;
  padding: 40px;
  right: 50%;

}



 }

 @media (max-width: 911px) {
 p.Banner-q{
     display: none;
 }
 h1.Banner-q{
     display: none;
 }

 }


@media (max-width: 475px) {

 .Banner {
    height: 300px;

  }

  .flexBoxSpaceWrap{
      display: none;
  }

  .bannerImg
  {
      height: 400px;
      width: auto;
  }

  .Banner-quotes {
    display: none;
  }

  p.Banner-q {
    display: none;

  }

  h1.Banner-q {
    font-family: PT Serif;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
  }

  .FilterBar {
    padding: 40px;
    align-items: center;
  }

  div.FilterBar-syle-div {
  position: absolute;
  align-items: center;
  padding: 40px;
  left: 0%;    

}
.extraSpaces{
display:none;
}

div.filterBarFlexBox{
position: relative;
align-items: center;
}
}

.post{
    text-align: center;
}

.headtext{
    font-size: 45px;
}

.bodytext{
    font-size: 30px;
}

.continue{
    width:100%;
}

@media (max-width: 560px) {
    .continue{
        font-size: 20px;
        width:100%;
    }
}
.interests,
.age {
	width: 35%;
	min-width: 100px;
	height: 25%;
	color: black;
	padding: 15px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 30px;
	margin: 10px 50px;
	cursor: pointer;
	border-radius: 25px;
	border: solid 0.5px #3473be;
	font-family: PT Serif;
	align-items: center;
	align-content: center;
}

.continue {
	width: 100%;
	height: 70px;
	padding: 15px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 30px;
	margin: 4px 2px;
	cursor: pointer;
	border-radius: 20px;
	border: solid 0.5px #3473be;
	background-color: #3473be;
	color: white;
	font-family: PT Serif;
	align-items: center;
}

.edithead {
	width: 100%;
	font-family: PT Serif;
	font-size: 62px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.31;
	letter-spacing: normal;
	text-align: center;
	color: #000000;
}

.interests:hover,
.age:hover {
	background-color: rgba(52, 115, 190, 0.2);
}

.age:focus {
	background-color: rgba(52, 115, 190, 0.2);
}

.topright {
	position: absolute;
	top: 8px;
	right: 60px;
	font-size: 20px;
	color: white;
	-webkit-filter: brightness(100%) !important;
	        filter: brightness(100%) !important;
}
.toggled {
	background-color: rgba(52, 115, 190, 0.2);
}
.icons {
	width: 70px;
	height: 70px;
	object-fit: contain;
	position: relative; /* Declared position allows for location changes */
	top: -10px; /* Moves the image 2px closer to the top of the page */
}

.activity {
	-webkit-filter: brightness(100%);
	        filter: brightness(100%);
}
.toggles {
	-webkit-filter: brightness(50%);
	        filter: brightness(50%);
}

.act {
	width: 75%;
}

.detailform {
	margin-left: 10px;
	margin-top: 11px;
	width: 100%;
}

@media (max-width: 560px) {
	.continue {
		font-size: 20px;
	}

	.icons {
		font-size: 20px;
	}

	.interests,
	.age {
		width: 35%;
		min-width: 190px;
		flex-flow: nowrap;
	}

	.edithead {
		font-size: 30px;
	}

	.detailform {
		width: 230px;
	}

	.headtext {
		font-size: 30px;
	}
}

.wrapper {
	display: flex;
	justify-content: flex-start;
	margin-left: 5%;
}
h1,
h2,
p {
	font-family: PT Serif;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.3;
	letter-spacing: normal;
}

h1.AboutHead {
	font-family: PT Serif;
}

h1.ProfileHead {
	width: 526px;
	font-family: PT Serif;
	font-size: 50px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.3;
	letter-spacing: normal;
	text-align: left;
	-webkit-margin-before: 0;
	        margin-block-start: 0;
	-webkit-margin-after: 0;
	        margin-block-end: 0;
}

h2.ProfileSec {
	height: 58px;
	font-family: PT Serif;
	font-size: 30px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.3;
	letter-spacing: normal;
	text-align: left;
	-webkit-margin-before: 0;
	        margin-block-start: 0;
	-webkit-margin-after: 0;
	        margin-block-end: 0;
}

.bio {
	font-size: 25px;
}

.likes {
	font-size: 20px;
}

h1.InterestHead {
	font-family: PT Serif;
}

p.info {
	font-size: 20px;
}
.leftside,
.rightside {
	padding: 10px;
	background-color: #fff;
}

.leftside {
	width: 30%;
}

.rightside {
	width: 60%;
	height: 100vh;
	top: 0;
	background-color: #fff;
}

.info {
	margin: 0 0 0;
	-webkit-margin-before: 0;
	        margin-block-start: 0;
	-webkit-margin-after: 0;
	        margin-block-end: 0;
}

@media (max-width: 480px) {
	h1.ProfileHead {
		width: 100%;
		font-family: PT Serif;
		font-size: 30px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: normal;
		text-align: left;
		-webkit-margin-before: 0;
		        margin-block-start: 0;
		-webkit-margin-after: 0;
		        margin-block-end: 0;
		margin-left: 20px;
	}

	h2.ProfileSec {
		height: 58px;
		font-family: PT Serif;
		font-size: 20px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.3;
		letter-spacing: normal;
		text-align: left;
		-webkit-margin-before: 0;
		        margin-block-start: 0;
		-webkit-margin-after: 0;
		        margin-block-end: 0;
		margin-left: 20px;
	}
}

.Covid-19 {
  color: blue;
}

p.covid {
  display: block;
  color: red;
}

div {
  font-family: PT Serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

eventAddr.Icon {
  width: 44px;
  height: 44px;
  object-fit: contain;
}

.eventAddr {
  font-family: PT Serif;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.eventName {
	font-family: PT Serif;
	font-size: 40px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.3;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
}
.body {
	background-color: white;
}
.longDescription {
	font-family: PT Serif;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
}

.shortDescription {
	font-family: PT Serif;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
}
.price {
	font-family: PT Serif;
	font-size: 35px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.3;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
	display: block;
	width: 100%;
	height: 50px;
}
.booknow {
	width: 200px;
}

.dateAndTime {
	font-family: PT Serif;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
}

.hours {
	/* //width: 180px; */
	font-family: PT Serif;
	font-size: 20px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
}

.button {
	background-color: #3875bb;
	border: none;
	border-radius: 8px;
	color: white;
	font-family: PT Serif;

	padding: 10px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 30px;
	margin: 4px 2px;
	cursor: pointer;
}

.shareButton {
	background-color: #4caf50;
	border: none;
	color: #707070;
	padding: 10px 20px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 15px;
	margin: 4px 2px;
	cursor: pointer;
	border-radius: 13px;
	border: solid 1px #707070;
	background-color: #ffffff;
}
.share-1 {
	width: 16px;
	height: 16px;
	object-fit: contain;
}
.frontHalf {
	background-color: #f0f0f0;
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 30px;
	padding-right: 30px;
}

.container {
	background-color: #ffffff;
	margin-top: 30px;
}

.relatedEventsFont {
	width: 416px;
	height: 77px;
	font-family: PT Serif;
	font-size: 40px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.31;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
}

.shareInterestedRow {
	position: relative;
	left: 3px;
}

@media (max-width: 480px) {
	.bookNowButton {
		position: fixed;
		width: 100%;
		align: center;
		z-index: 1;
		bottom: 0;
		left: 10%;
	}

	.eventName {
		font-size: 30px;
	}

	.price {
		font-size: 40px;
		height: 25%;
	}
}

.bookNowButton
{
height: 40px;
width: 80%;
font-size: 18px;
}

.bookNowButtonDiv
{
	width: 100%;
}

.continuePage{
	color: #0d47a1
}

@media (max-width: 480px) {


}
.wrapper {
	display: flex;
	justify-content: flex-start;
}

.main,
.sidebar {
	padding: 10px;
	background-color: #fff;
}

.main {
	width: 70%;
}

.sidebar {
	width: 25%;
	height: auto;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	background-color: #fff;
}

/* @media (max-Width: 460px) {
	.wrapper {
		display: normal;
		justify-content: flex-start;
	}
	.main {
		position: relative;
		top: 270px;
		left: -90px;
	}
	.sidebar {
		width: 100%;
		left: 120px;
	}
} */

.termsandc{
    color: black;
    font-family: PT Serif;
    margin-left: 20%;
    margin-right: 20%;
}

.head{
    color:black;
    font-family: PT Serif;
}
.body{
    color:black;
    font-family: PT Serif;
    font-size: 15px;
}
font-face {
  font-family: newFont;
  src: url(/static/media/Aileron-Light.5fa4803a.otf);
}

.row {
  display: flex;
}

.row2 {
  display: flex;
}

.column {
  flex: 50% 1;

}

.bgimg-1 {
  background-image: url(/static/media/image1.e6bc46ba.jpg);
  height:200px;
  width: auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.photo2 {
max-width: 100%;
height: auto;
object-fit: contain;
display: flex;
flex-flow: row nowrap;
align-items: center;
}

.photo3 {
max-width: 100%;
height: auto;
object-fit: contain;
display: flex;
flex-flow: row nowrap;
align-items: center;
}

.photo4 {
max-width: 100%;
height: auto;
object-fit: contain;
display: flex;
flex-flow: row nowrap;
align-items: center;
}

.photo5 {
max-width: 100%;
height: auto;
object-fit: contain;
display: flex;
flex-flow: row nowrap;
align-items: center;
}


.banner {

  background-image: url(/static/media/Banner.aa515f40.jpg);
  width: 100%;
  min-height:250px;
}

h3 {

  letter-spacing: 3px;
  font: 50px "Lato", newFont;
  color: #000000;
  width: 100%;

}

h2{

  letter-spacing: 3px;
  font-family: PT Serif;
    font-size: 24px;
  color: #000000;
  width: 100%;

}

title {

  letter-spacing: 3px;
  font: 50px "Lato", newFont;
  color: #000000;
  width: 100%;

}

.button {
  position: relative;
  background-color: #3875bb; /* Green */
  border: none;
  color: white;
  padding: 35px 60px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
  cursor: pointer;
  border-radius: 12px;
  margin:0 auto;
  display:block;
}

@media (max-width: 480px) {
.column {
  flex: 50% 1;
  max-width: 100%;
}
h3 {

  letter-spacing: 3px;
  font: 30px "Lato", newFont;
  color: #000000;
  width: 100%;

}

h2 {

  letter-spacing: 3px;
  font-family: PT Serif;
  font-size: 24px;
  color: #000000;
  width: 100%;

}

title {

  letter-spacing: 3px;
  font: 50px "Lato", newFont;
  color: #000000;
  width: 100%;

}

.row2 {
  display: flex;
  flex-wrap: wrap-reverse;
}
}


