font-face {
  font-family: newFont;
  src: url(Aileron-Light.otf);
}

.row {
  display: flex;
}

.row2 {
  display: flex;
}

.column {
  flex: 50%;

}

.bgimg-1 {
  background-image: url("./Images/image1.jpg");
  height:200px;
  width: auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.photo2 {
max-width: 100%;
height: auto;
object-fit: contain;
display: flex;
flex-flow: row nowrap;
align-items: center;
}

.photo3 {
max-width: 100%;
height: auto;
object-fit: contain;
display: flex;
flex-flow: row nowrap;
align-items: center;
}

.photo4 {
max-width: 100%;
height: auto;
object-fit: contain;
display: flex;
flex-flow: row nowrap;
align-items: center;
}

.photo5 {
max-width: 100%;
height: auto;
object-fit: contain;
display: flex;
flex-flow: row nowrap;
align-items: center;
}


.banner {

  background-image: url("./Images/Banner.jpg");
  width: 100%;
  min-height:250px;
}

h3 {

  letter-spacing: 3px;
  font: 50px "Lato", newFont;
  color: #000000;
  width: 100%;

}

h2{

  letter-spacing: 3px;
  font-family: PT Serif;
    font-size: 24px;
  color: #000000;
  width: 100%;

}

title {

  letter-spacing: 3px;
  font: 50px "Lato", newFont;
  color: #000000;
  width: 100%;

}

.button {
  position: relative;
  background-color: #3875bb; /* Green */
  border: none;
  color: white;
  padding: 35px 60px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
  cursor: pointer;
  border-radius: 12px;
  margin:0 auto;
  display:block;
}

@media (max-width: 480px) {
.column {
  flex: 50%;
  max-width: 100%;
}
h3 {

  letter-spacing: 3px;
  font: 30px "Lato", newFont;
  color: #000000;
  width: 100%;

}

h2 {

  letter-spacing: 3px;
  font-family: PT Serif;
  font-size: 24px;
  color: #000000;
  width: 100%;

}

title {

  letter-spacing: 3px;
  font: 50px "Lato", newFont;
  color: #000000;
  width: 100%;

}

.row2 {
  display: flex;
  flex-wrap: wrap-reverse;
}
}

