.post{
    text-align: center;
}

.headtext{
    font-size: 45px;
}

.bodytext{
    font-size: 30px;
}

.continue{
    width:100%;
}

@media (max-width: 560px) {
    .continue{
        font-size: 20px;
        width:100%;
    }
}