.bookNowButton
{
height: 40px;
width: 80%;
font-size: 18px;
}

.bookNowButtonDiv
{
	width: 100%;
}

.continuePage{
	color: #0d47a1
}

@media (max-width: 480px) {


}