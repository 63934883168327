.Covid-19 {
  color: blue;
}

p.covid {
  display: block;
  color: red;
}

div {
  font-family: PT Serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
