.termsandc{
    color: black;
    font-family: PT Serif;
    margin-left: 20%;
    margin-right: 20%;
}

.head{
    color:black;
    font-family: PT Serif;
}
.body{
    color:black;
    font-family: PT Serif;
    font-size: 15px;
}