
.Banner {
  height: 800px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}


.Banner-quotes {
position: relative;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 40px;
    top: -10px;
}

  p.Banner-q {
    font-family: PT Serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    margin-bottom: 0;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }



  h1.Banner-q {
    font-family: PT Serif;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
  }

.FilterBar {
  width: 527px;
  height: 448px;
  border-radius: 13px;
  background-color: #ffffff;
  align-items: center;
  
}
div.FilterBar-syle-div {
  align-items: center;
  padding: 40px;
  right: 10%;
}



h1.container {
  padding-left: 20px;
}
p.container {
  padding-left: 20px;
}

.EventCardText {
    font-family: PT Serif;
}


@media (max-width: 970px) {
  p.Banner-q {
    font-family: PT Serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    margin-bottom: 0;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  h1.Banner-q {
    font-family: PT Serif;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
  }

 div.FilterBar-syle-div {
  position: relative;
  align-items: center;
  padding: 40px;
  right: 50%;

}



 }

 @media (max-width: 911px) {
 p.Banner-q{
     display: none;
 }
 h1.Banner-q{
     display: none;
 }

 }


@media (max-width: 475px) {

 .Banner {
    height: 300px;

  }

  .flexBoxSpaceWrap{
      display: none;
  }

  .bannerImg
  {
      height: 400px;
      width: auto;
  }

  .Banner-quotes {
    display: none;
  }

  p.Banner-q {
    display: none;

  }

  h1.Banner-q {
    font-family: PT Serif;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
  }

  .FilterBar {
    padding: 40px;
    align-items: center;
  }

  div.FilterBar-syle-div {
  position: absolute;
  align-items: center;
  padding: 40px;
  left: 0%;    

}
.extraSpaces{
display:none;
}

div.filterBarFlexBox{
position: relative;
align-items: center;
}
}
