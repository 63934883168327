.eventName {
	font-family: PT Serif;
	font-size: 40px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.3;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
}
.body {
	background-color: white;
}
.longDescription {
	font-family: PT Serif;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
}

.shortDescription {
	font-family: PT Serif;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
}
.price {
	font-family: PT Serif;
	font-size: 35px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.3;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
	display: block;
	width: 100%;
	height: 50px;
}
.booknow {
	width: 200px;
}

.dateAndTime {
	font-family: PT Serif;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
}

.hours {
	/* //width: 180px; */
	font-family: PT Serif;
	font-size: 20px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
}

.button {
	background-color: #3875bb;
	border: none;
	border-radius: 8px;
	color: white;
	font-family: PT Serif;

	padding: 10px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 30px;
	margin: 4px 2px;
	cursor: pointer;
}

.shareButton {
	background-color: #4caf50;
	border: none;
	color: #707070;
	padding: 10px 20px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 15px;
	margin: 4px 2px;
	cursor: pointer;
	border-radius: 13px;
	border: solid 1px #707070;
	background-color: #ffffff;
}
.share-1 {
	width: 16px;
	height: 16px;
	object-fit: contain;
}
.frontHalf {
	background-color: #f0f0f0;
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 30px;
	padding-right: 30px;
}

.container {
	background-color: #ffffff;
	margin-top: 30px;
}

.relatedEventsFont {
	width: 416px;
	height: 77px;
	font-family: PT Serif;
	font-size: 40px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.31;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
}

.shareInterestedRow {
	position: relative;
	left: 3px;
}

@media (max-width: 480px) {
	.bookNowButton {
		position: fixed;
		width: 100%;
		align: center;
		z-index: 1;
		bottom: 0;
		left: 10%;
	}

	.eventName {
		font-size: 30px;
	}

	.price {
		font-size: 40px;
		height: 25%;
	}
}
