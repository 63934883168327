title {
  word-wrap: break-word; /* All browsers since IE 5.5+ */
  overflow-wrap: break-word; /* Renamed property in CSS3 draft spec */
  width: 100%;
}

.topHeading {
  vertical-align: baseline;
  font-weight: 400;
  font-family: PT Serif;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}
div.Card:hover {
  /* opacity: 99%; */
  cursor: pointer;
}

.h1 {
  font-family: PT Serif;
  font-size: 25px;
  font-weight: 400;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.bottomPortion{
    position: relative;
    top: 550px;
}

.priceDescription{
    position: absolute;
    top: 520px;
}
