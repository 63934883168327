.interests,
.age {
	width: 35%;
	min-width: 100px;
	height: 25%;
	color: black;
	padding: 15px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 30px;
	margin: 10px 50px;
	cursor: pointer;
	border-radius: 25px;
	border: solid 0.5px #3473be;
	font-family: PT Serif;
	align-items: center;
	align-content: center;
}

.continue {
	width: 100%;
	height: 70px;
	padding: 15px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 30px;
	margin: 4px 2px;
	cursor: pointer;
	border-radius: 20px;
	border: solid 0.5px #3473be;
	background-color: #3473be;
	color: white;
	font-family: PT Serif;
	align-items: center;
}

.edithead {
	width: 100%;
	font-family: PT Serif;
	font-size: 62px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.31;
	letter-spacing: normal;
	text-align: center;
	color: #000000;
}

.interests:hover,
.age:hover {
	background-color: rgba(52, 115, 190, 0.2);
}

.age:focus {
	background-color: rgba(52, 115, 190, 0.2);
}

.topright {
	position: absolute;
	top: 8px;
	right: 60px;
	font-size: 20px;
	color: white;
	filter: brightness(100%) !important;
}
.toggled {
	background-color: rgba(52, 115, 190, 0.2);
}
.icons {
	width: 70px;
	height: 70px;
	object-fit: contain;
	position: relative; /* Declared position allows for location changes */
	top: -10px; /* Moves the image 2px closer to the top of the page */
}

.activity {
	filter: brightness(100%);
}
.toggles {
	filter: brightness(50%);
}

.act {
	width: 75%;
}

.detailform {
	margin-left: 10px;
	margin-top: 11px;
	width: 100%;
}

@media (max-width: 560px) {
	.continue {
		font-size: 20px;
	}

	.icons {
		font-size: 20px;
	}

	.interests,
	.age {
		width: 35%;
		min-width: 190px;
		flex-flow: nowrap;
	}

	.edithead {
		font-size: 30px;
	}

	.detailform {
		width: 230px;
	}

	.headtext {
		font-size: 30px;
	}
}
