.footer{
    font-family: PT Serif;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    background-color:#707070;
    margin-top: 5px;

    
}

.footerText{
    margin-right: 7%;
    margin-left: 7%;
}

.email{
    font-size: 25px;
}
.loc{
    font-size: 20px;
}

h1.aboutheader{
    font-family: PT Serif;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    font-size: 20px
}

p.about{
    font-size: 15px
}

p.copyright{
    font-family: PT Serif;
    font-size: 23px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.snsbutton{
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.tandc{
    color: white
}